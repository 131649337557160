(function() {
    'use strict';

    angular.module('ionicConfirmation', [
        'ionic',
        'uabDefaultVariable'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicConfirmation').controller(
        'IonicConfirmationController',
        IonicConfirmationController
    );

    IonicConfirmationController.$inject = [
        'DefaultVariableService',
        'IonicConfirmationService'
    ];

    function IonicConfirmationController(
        DefaultVariableService,
        IonicConfirmationService
    ) {
        var IonicConfirmationController = this;

        IonicConfirmationController.addMessage = addMessage;
        function addMessage(message) {
            message = DefaultVariableService.getString(
                message,
                IonicConfirmationController.message
            );

            return IonicConfirmationService.createConfirmation(message);
        }

        IonicConfirmationController.reset = reset;
        function reset() {
            IonicConfirmationController.message = '';
        }

        IonicConfirmationController.init = init;
        function init() {
            IonicConfirmationController.reset();
        }

        IonicConfirmationController.init();
    }
})();
(function() {
    'use strict';

    angular.module('ionicConfirmation').directive(
        'ionicConfirmation',
        ionicConfirmation
    );

    function ionicConfirmation() {
        return {
            controller:   'IonicConfirmationController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {},
            template:'<div><input type="text" data-ng-model="ctrl.message"> <input type="button" value="CREATE" data-ng-click="ctrl.addMessage()"></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicConfirmation').service(
        'IonicConfirmationService',
        IonicConfirmationService
    );

    IonicConfirmationService.$inject = [
        'DefaultVariableService',
        '$ionicPopup'
    ];

    function IonicConfirmationService(
        DefaultVariableService,
        $ionicPopup
    ) {
        var IonicConfirmationService = this;

        var DEFAULT_ACCEPT_TEXT = 'OK';
        var DEFAULT_DECLINE_TEXT = 'CANCEL';
        var DEFAULT_MESSAGE = 'PROCEED?';

        IonicConfirmationService.createConfirmation = createConfirmation;
        function createConfirmation(message, acceptText, declineText) {
            message = DefaultVariableService.get(
                message,
                DEFAULT_MESSAGE
            );

            acceptText = DefaultVariableService.get(
                acceptText,
                DEFAULT_ACCEPT_TEXT
            );

            declineText = DefaultVariableService.get(
                declineText,
                DEFAULT_DECLINE_TEXT
            );

            return $ionicPopup.confirm({
                title:       'CONFIRM',
                template:    message,
                cancelText:  declineText,
                cancelType:  'decline-button',
                okText:      acceptText,
                okType:      'accept-button'
            });
        }

        return IonicConfirmationService;
    }
})();